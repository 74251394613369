import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Button, Card, Container, Header, Image, Modal, Segment } from 'semantic-ui-react'

import { useAuth0 } from '../auth/auth0'
import withAuth from '../auth/auth-wrapper'

const Profile = (_: RouteComponentProps) => {
  const { loading, user } = useAuth0()

  if (loading || !user) {
    return <div>Loading...</div>
  }

  return (
    <Container>
      <Segment vertical>
        <Card>
          <Image src={user.picture} wrapped ui={false} />
          <Card.Content>
            <Card.Header>{user.name}</Card.Header>
            <Card.Meta>{user.email}</Card.Meta>
          </Card.Content>
          <Card.Content extra>
            <Modal trigger={<Button size="mini">User Properties</Button>} basic size="small">
              <Header icon="user" content="Raw User Object" />
              <Modal.Content>
                <pre>
                  <code>{JSON.stringify(user, null, 2)}</code>
                </pre>
              </Modal.Content>
            </Modal>
          </Card.Content>
        </Card>
      </Segment>
    </Container>
  )
}

export default withAuth(Profile)
