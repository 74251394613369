/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ReferralEventStatus {
  Dispatched = "Dispatched",
  Error = "Error",
  Pending = "Pending",
  Received = "Received",
}

export enum ReferralEventType {
  PatientReferralNotice = "PatientReferralNotice",
  SiteReferralNotice = "SiteReferralNotice",
  SponsorReferralNotice = "SponsorReferralNotice",
}

/**
 * Represents the status of a Patient Trial Referral.
 * 
 * Workflow:
 *   Requested => Referred
 *   Referred => Screening || NotEnrolled
 *   Screening => Enrolled || NotEnrolled
 *   Enrolled => Completed
 */
export enum ReferralStatus {
  Completed = "Completed",
  Enrolled = "Enrolled",
  NotEnrolled = "NotEnrolled",
  Referred = "Referred",
  Requested = "Requested",
  Screening = "Screening",
}

export enum ReferralType {
  ConditionReferral = "ConditionReferral",
  PatientReferral = "PatientReferral",
}

export enum TrialGender {
  All = "All",
  Female = "Female",
  Male = "Male",
}

export enum TrialStatus {
  Complete = "Complete",
  Other = "Other",
  Pending = "Pending",
  Recruiting = "Recruiting",
  Suspended = "Suspended",
}

export interface ContactInput {
  name: string;
  email?: string | null;
  phone?: string | null;
  address?: PostalAddressInput | null;
}

export interface PostalAddressInput {
  name?: string | null;
  street?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
  country?: string | null;
}

export interface ReferralEventsInput {
  referral_id: string;
}

export interface SendReferralNoticeInput {
  referral_id: string;
  event_type: ReferralEventType;
  contacts?: ContactInput[] | null;
}

export interface UpdateReferralInput {
  referral_id: string;
  status: ReferralStatus;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
