import moment from 'moment'
import React from 'react'
import { Header, Label, List, Segment } from 'semantic-ui-react'
import { ReferralStatus } from '../../__generated__/globalTypes'

interface ReferralListProps {
  clickHandler: Function
  referrals: any[]
  activeReferralId: string
}

const ReferralList: React.FC<ReferralListProps> = props => {
  return (
    <Segment vertical>
      <Header as="h3" dividing>
        Patient Referrals
      </Header>
      <List selection relaxed divided>
        {props.referrals.map(referral => (
          <List.Item
            active={referral.id === props.activeReferralId}
            onClick={() => props.clickHandler(referral)}
            key={referral.id}
          >
            <List.Content floated="right">
              <Label content={referral.status} color={referral.status === ReferralStatus.Requested ? 'blue' : 'grey'} horizontal={true} size="small" />
              <br />
              {moment(referral.created_at).format('L')}
            </List.Content>
            <List.Header as="a">{referral.contact.name}</List.Header>
            <List.Content>{referral.contact!.address!.postal_code}<br />{referral.trial_number}</List.Content>
          </List.Item>
        ))}
      </List>
    </Segment>
  )
}

export default ReferralList
