import React from 'react'
import { Router, Redirect } from '@reach/router'
import { ApolloProvider } from 'react-apollo'

import { Auth0Provider, Auth0Context } from './auth/auth0'
import tenantApiClient from './graphql/tenant-api-client'

import Dashboard from './pages/Dashboard'
import Login from './pages/login'
import Profile from './components/profile'
import Referrals from './pages/referrals'

import './App.css'
import Advocates from './containers/advocates'
import { Dimmer, Loader } from 'semantic-ui-react'

const domain = process.env.REACT_APP_AUTH0_DOMAIN || ''
const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || ''

class App extends React.Component {
  render() {
    return (
      <Auth0Provider domain={domain} client_id={client_id} audience={audience}>
        <Auth0Context.Consumer>
          {({ loading, accessToken }: any) => {
            if (loading) {
              return (
                <Dimmer active inverted page>
                  <Loader content="Loading Application" />
                </Dimmer>
              )
            }

            const client = tenantApiClient(accessToken)
            return (
              <ApolloProvider client={client}>
                <Router>
                  <Redirect from="/" to="advocates/" noThrow />
                  <Advocates path="advocates">
                    <Dashboard path="/" />
                    <Referrals path="referrals" only="user" />
                    <Profile path="profile" only="user" />
                  </Advocates>
                  <Login path="login" only="anonymous" redirect="/advocates" />
                </Router>
              </ApolloProvider>
            )
          }}
        </Auth0Context.Consumer>
      </Auth0Provider>
    )
  }
}

export default App
