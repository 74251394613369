import React from 'react'
import { Button } from 'semantic-ui-react'
import { useMutation } from 'react-apollo'
import _ from 'lodash'

import { ReferralEventType } from '../../__generated__/globalTypes'
import { SEND_REFERRAL_NOTICE } from './mutations'
import { REFERRAL_EVENTS_QUERY } from './queries'
import { ReferralEvents } from './__generated__/ReferralEvents'
import { ReferralsQuery_referrals } from './__generated__/ReferralsQuery'

interface ReferralNotifyButtonProps {
  referral: ReferralsQuery_referrals
  eventType: ReferralEventType
}

const ReferralNotifyButton: React.FC<ReferralNotifyButtonProps> = ({ referral, eventType }) => {
  const [sendReferral, { loading }] = useMutation(SEND_REFERRAL_NOTICE, {
    update(cache, { data: { event } }) {
      const { events } = cache.readQuery({
        query: REFERRAL_EVENTS_QUERY,
        variables: {
          input: { referral_id: referral.id }
        }
      }) as ReferralEvents
      cache.writeQuery({
        query: REFERRAL_EVENTS_QUERY,
        variables: {
          input: { referral_id: referral.id }
        },
        data: { events: [event].concat(events) }
      })
    }
  })

  const contacts = eventType === ReferralEventType.SponsorReferralNotice ? _.get(referral, "sponsor.contacts", []) : _.get(referral, "site.contacts", [])
  const contactables = contacts.filter((c: any) => Boolean(c.email))

  const handleSend = () => {
    sendReferral({
      variables: {
        input: {
          referral_id: referral.id,
          event_type: eventType,
        }
      }
    })
  }

  return (
    <Button color="blue" loading={loading} onClick={handleSend} disabled={!contactables.length}>
      Notify {eventType === ReferralEventType.SponsorReferralNotice ? `Sponsor` : `Site`}
    </Button>
  )
}

export default ReferralNotifyButton
