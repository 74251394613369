import React from 'react'
import { List } from 'semantic-ui-react'

import { Trial_trial_sites } from './__generated__/Trial'
import TrialContactList from './trial-contact-list'
import { ReferralsQuery_referrals_site } from './__generated__/ReferralsQuery'

interface TrialSiteListProps {
  sites: (Trial_trial_sites | ReferralsQuery_referrals_site | null)[]
}

const TrialSiteList: React.FC<TrialSiteListProps> = ({ sites }) => {
  return (
    <List divided>
      {sites.filter(s => Boolean(s)).map((site, i) => (
        <List.Item key={i}>
          <List.Content>
            <List.Header icon="user" content={site!.name || "[Unamed Site]"} />
            <List.Description icon="mail">
              {site!.city || "[city]"}, {site!.state || "[state]"}, {site!.postal_code || "[postal]"}, {site!.country || "[country]"}
            </List.Description>
            <TrialContactList contacts={site!.contacts} />
          </List.Content>
        </List.Item>
      ))}
    </List>
  )
}

export default TrialSiteList
