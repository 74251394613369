import gql from 'graphql-tag'

export const UPDATE_REFERRAL_MUTATION = gql`
  mutation UpdateReferral($input: UpdateReferralInput!) {
    referral: updateReferral(input: $input) {
      id
      status
    }
  }
`

export const SEND_REFERRAL_NOTICE = gql`
  mutation SendReferralNotice($input: SendReferralNoticeInput!) {
    event: sendReferralNotice(input: $input) {
      id
      referral_id
      type
      status
      description
      created_at
      updated_at
      payload
    }
  }
`
