import React from 'react'
import { Header, Container, Segment, Button } from 'semantic-ui-react'
import { RouteComponentProps } from '@reach/router'
import { useAuth0 } from '../../auth/auth0'
import withAuth from '../../auth/auth-wrapper'

const LoginPage: React.FC<RouteComponentProps> = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <Container>
      <Segment style={{ padding: '8em 0em', textAlign: 'center' }} vertical>
        <Header
          as="h1"
          content="Advocate Center"
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
          }}
        />
        <Header
          as="h2"
          content="Aloha Health Network"
          style={{
            fontSize: '2em',
            fontWeight: 'normal',
          }}
        />
        <Button primary size="huge" onClick={() => loginWithRedirect({ redirect_uri: window.location.origin })}>
          Please Login
        </Button>
      </Segment>
    </Container>
  )
}

export default withAuth(LoginPage)
