import React from 'react'
import { Link } from '@reach/router'
import { Responsive, Visibility, Segment, Menu, Container, Grid, Header, List } from 'semantic-ui-react'

import AuthControl from '../auth/auth-control'

interface DesktopContainerState {
  fixed: boolean
}

export default class DesktopContainer extends React.Component<{}, DesktopContainerState> {
  state: DesktopContainerState = {
    fixed: false,
  }

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
          <Segment inverted textAlign="center" style={{ minHeight: 75, padding: '1em 0em' }} vertical>
            <Menu fixed={fixed ? 'top' : undefined} inverted={!fixed} pointing={!fixed} secondary={!fixed} size="large">
              <Container>
                <Menu.Item as={Link} to="/advocates">
                  Dashboard
                </Menu.Item>
                <Menu.Item as={Link} to="/advocates/referrals">
                  Referrals
                </Menu.Item>
                <Menu.Item position="right">
                  <AuthControl />
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
        </Visibility>

        {children}

        <Segment inverted vertical style={{ padding: '5em 0em' }}>
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Header inverted as="h4" content="About" />
                  <List link inverted>
                    <List.Item as="a">Sitemap</List.Item>
                    <List.Item as="a">Contact Us</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as="h4" content="Services" />
                  <List link inverted>
                    <List.Item as="a">Trial Matching</List.Item>
                    <List.Item as="a">Referrals</List.Item>
                    <List.Item as="a">Documentation</List.Item>
                    <List.Item as="a">FAQs</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Header as="h4" inverted>
                    Footer Header
                  </Header>
                  <p>Extra space for a call to action inside the footer that could help re-engage users.</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </Responsive>
    )
  }
}
