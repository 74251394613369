import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'

type TAuth0ProviderProps = { onRedirectCallback?: Function } & Auth0ClientOptions
type TAuth0Context = {
  accessToken: string
  getIdTokenClaims: Function
  getTokenSilently: Function
  handleRedirectCallback: Function
  isAuthenticated: boolean
  loading: boolean
  loginWithRedirect: Function
  logout: Function
  user: any
}
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<TAuth0Context>({
  accessToken: '',
  getIdTokenClaims: Function.prototype,
  getTokenSilently: Function.prototype,
  handleRedirectCallback: Function.prototype,
  isAuthenticated: false,
  loading: true,
  loginWithRedirect: Function.prototype,
  logout: Function.prototype,
  user: {},
})

export const useAuth0 = () => useContext(Auth0Context)

export const Auth0Provider: React.FC<TAuth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [accessToken, setAccessToken] = useState()

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)

        const accessToken = await auth0FromHook.getTokenSilently()
        console.log(accessToken)
        setAccessToken(accessToken)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }

  return (
    <Auth0Context.Provider
      value={{
        accessToken,
        isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...args: getIdTokenClaimsOptions[]) => auth0Client.getIdTokenClaims(...args),
        loginWithRedirect: (...args: RedirectLoginOptions[]) => auth0Client.loginWithRedirect(...args),
        getTokenSilently: (...args: GetTokenSilentlyOptions[]) => auth0Client.getTokenSilently(...args),
        logout: (...args: LogoutOptions[]) => auth0Client.logout(...args),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
