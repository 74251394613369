import React from 'react'
import { Button, Container, Divider, Grid, Header, Image, Segment } from 'semantic-ui-react'

import { useAuth0 } from '../../../auth/auth0'

import AdvocateImg from './advocating.jpg'
import EricDrew from './EricDrew.jpg'

const AnonymousDashboard = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <Container>
      <Segment style={{ padding: '8em 0em', textAlign: 'center' }} vertical>
        <Header
          as="h1"
          content="Advocate Center"
          style={{
            fontSize: '4em',
            fontWeight: 'normal',
          }}
        />
        <Header
          as="h2"
          content="Aloha Health Network"
          style={{
            fontSize: '2em',
            fontWeight: 'normal',
          }}
        />
        <Button primary size="huge" onClick={() => loginWithRedirect({ redirect_uri: window.location.origin })}>
          Get Started
        </Button>
      </Segment>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                Help Patients Find Advanced Therapies
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                Our goal is to get every patient the optimal treatment for their individual disease or medical
                condition. By making sure that patients have a comprehensive list of their options, we give them the
                best chances of finding the optimal treatment and having an optimal outcome!
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={6}>
              <Image bordered rounded size="large" src={AdvocateImg} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button size="huge" onClick={() => loginWithRedirect({ redirect_uri: window.location.origin })}>
                Start your advocate journey now.
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: '0em' }} vertical>
        <Grid celled="internally" columns="equal" stackable>
          <Grid.Row textAlign="center">
            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                "What a Company"
              </Header>
              <p style={{ fontSize: '1.33em' }}>That is what they all say about us</p>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                "We've helped so many patients find advanced treatment using Aloha."
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                <Image avatar src={EricDrew} />
                <b>Eric Drew</b> Founder WeHeal.org
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Header as="h3" style={{ fontSize: '2em' }}>
            Breaking The Grid, Grabs Your Attention
          </Header>
          <p style={{ fontSize: '1.33em' }}>
            Instead of focusing on content creation and hard work, we have learned how to master the art of doing
            nothing by providing massive amounts of whitespace and generic content that can seem massive, monolithic and
            worth your attention.
          </p>
          <Button as="a" size="large">
            Read More
          </Button>

          <Divider as="h4" className="header" horizontal style={{ margin: '3em 0em', textTransform: 'uppercase' }}>
            <span>Case Studies</span>
          </Divider>

          <Header as="h3" style={{ fontSize: '2em' }}>
            Did We Tell You About Our Bananas?
          </Header>
          <p style={{ fontSize: '1.33em' }}>
            Yes I know you probably disregarded the earlier boasts as non-sequitur filler content, but it's really true.
            It took years of gene splicing and combinatory DNA research, but our bananas can really dance.
          </p>
          <Button as="a" size="large">
            I'm Still Quite Interested
          </Button>
        </Container>
      </Segment>
    </Container>
  )
}

export default AnonymousDashboard
