import React from 'react'

import DesktopContainer from './desktop'
import MobileContainer from './mobile'
import { RouteComponentProps } from '@reach/router'

const Advocates: React.FC<RouteComponentProps> = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

export default Advocates
