import React from 'react'
import { Link } from '@reach/router'
import { Responsive, Sidebar, Menu, Segment, Container, Icon } from 'semantic-ui-react'

import AuthControl from '../auth/auth-control'

interface MobileContainerState {
  sidebarOpened: boolean
}

export default class MobileContainer extends React.Component<{}, MobileContainerState> {
  state = {
    sidebarOpened: false,
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive as={Sidebar.Pushable} maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar as={Menu} animation="push" inverted onHide={this.handleSidebarHide} vertical visible={sidebarOpened}>
          <Menu.Item as={Link} to="/">
            Dashboard
          </Menu.Item>
          <Menu.Item as={Link} to="/referrals">
            Referrals
          </Menu.Item>
          <Menu.Item>
            <AuthControl />
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment inverted textAlign="center" style={{ minHeight: 75, padding: '1em 0em' }} vertical>
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}
