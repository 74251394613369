import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Container, Segment, Dimmer, Loader, Grid } from 'semantic-ui-react'

import withAuth from '../../auth/auth-wrapper'

import ReferralList from './referral-list'
import { REFERRALS_QUERY } from './queries'
import ReferralDetail from './referral-detail'
import ReferralEventsList from './referral-events'

const Referrals: React.FC = (props) => {
  const [activeReferral, setActiveReferral] = useState()
  const { loading, error, data } = useQuery(REFERRALS_QUERY)

  return (
    <Container>
      <Segment vertical>
        {loading && (
          <Dimmer active inverted page>
            <Loader content="Loading Referrals" />
          </Dimmer>
        )}
        {error && <p>Error</p>}
        {data && (
          <Grid divided>
            <Grid.Column width={4}>
              <ReferralList
                referrals={data!.referrals}
                activeReferralId={activeReferral && activeReferral.id}
                clickHandler={setActiveReferral}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              {activeReferral && <ReferralDetail referral={activeReferral} />}
              {activeReferral && <ReferralEventsList referralId={activeReferral.id} />}
            </Grid.Column>
          </Grid>
        )}
      </Segment>
    </Container >
  )
}

export default withAuth(Referrals)
