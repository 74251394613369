import React from 'react'
import { Link } from '@reach/router'

import { useAuth0 } from '../auth/auth0'

const AuthControl: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

  if (!isAuthenticated) {
    return (
      <Link to="/advocates" onClick={() => loginWithRedirect({ redirect_uri: window.location.origin })}>
        Log In
      </Link>
    )
  } else {
    return (
      <Link to="/" onClick={() => logout()}>
        Log Out
      </Link>
    )
  }
}

export default AuthControl
