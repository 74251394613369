import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'

const endpoint = process.env.REACT_APP_TENANT_API

const httpLink = createHttpLink({
  uri: endpoint,
})

const tokenAuthLink = (token: string) => {
  return setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })
}

const tenantApiClient = (authToken: string) => {
  return new ApolloClient({
    link: tokenAuthLink(authToken).concat(httpLink),
    cache: new InMemoryCache(),
  })
}

export default tenantApiClient
