import React from 'react'
import { List } from 'semantic-ui-react'

import { ReferralsQuery_referrals_sponsor_contacts } from './__generated__/ReferralsQuery'

interface TrialContactListProps {
  contacts: (ReferralsQuery_referrals_sponsor_contacts | null)[]
}

const TrialContactList: React.FC<TrialContactListProps> = ({ contacts }) => {
  return (
    <List>
      {contacts.map(c => (
        <List.Item key={c!.email || 'key'}>
          <List.Content>
            <List.Header icon="user" content={c!.name} />
            <List.Description icon="mail" content={c!.email} />
            <List.Description icon="phone" content={c!.phone} />
          </List.Content>
        </List.Item>
      ))}
    </List>
  )
}

export default TrialContactList
