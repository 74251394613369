import React from 'react'
import { Segment, Header, Table } from 'semantic-ui-react'
import _ from 'lodash'
import moment from 'moment'

import { ReferralEventType } from '../../__generated__/globalTypes'
import TrialSiteList from './trial-site-list'
import TrialContactList from './trial-contact-list'
import ReferralNotifyButton from './referral-notify-button'
import { ReferralsQuery_referrals } from './__generated__/ReferralsQuery'

interface ReferralDetailProps {
  referral: ReferralsQuery_referrals
}


const ReferralDetail: React.FC<ReferralDetailProps> = ({ referral }) => {
  return (
    <Segment vertical>
      <Header as="h3" dividing>
        Referral Details
    </Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Contact</Table.Cell>
            <Table.Cell>
              <strong>{referral.contact!.name}</strong><br />
              {referral.contact!.email}<br />
              {referral.contact!.address!.postal_code}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Referral</Table.Cell>
            <Table.Cell><strong>{referral.code}</strong></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Trial</Table.Cell>
            <Table.Cell>
              <div><strong>{referral.trial_number}</strong></div>
              <div>{referral.trial_title}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Sponsor</Table.Cell>
            <Table.Cell>
              <div><strong>{_.get(referral, "sponsor.name")}</strong></div>
              <TrialContactList contacts={_.get(referral, "sponsor.contacts", [])} />
              <ReferralNotifyButton referral={referral} eventType={ReferralEventType.SponsorReferralNotice} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Site</Table.Cell>
            <Table.Cell>
              <TrialSiteList sites={[referral.site]} />
              <ReferralNotifyButton referral={referral} eventType={ReferralEventType.SiteReferralNotice} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Status</Table.Cell>
            <Table.Cell>
              {referral.status}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Created</Table.Cell>
            <Table.Cell>{moment(referral.created_at).format('LLL')}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Updated</Table.Cell>
            <Table.Cell>{moment(referral.updated_at).format('LLL')}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  )
}

export default ReferralDetail
