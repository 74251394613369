import gql from 'graphql-tag'

export const REFERRALS_QUERY = gql`
  query ReferralsQuery {
    referrals {
      id
      code
      type
      trial_id
      trial_number
      trial_title
      status
      contact {
        name
        email
        address {
          city
          state
          postal_code
          country
          formatted
          location {
            lat
            lng
          }
        }
      }
      site {
        name
        city
        state
        country
        postal_code
        contacts {
          name
          email
          phone
        }
      }
      sponsor {
        name
        contacts {
          name
          email
          phone
        }
      }
      patient_id
      health_profile_id
      created_at
      updated_at
    }
  }
`

export const REFERRAL_EVENTS_QUERY = gql`
  query ReferralEvents($input: ReferralEventsInput!) {
    events: referralEvents(input: $input) {
      id
      status
      type
      description
      updated_at
    }
  }
`

export const TRIAL_QUERY = gql`
  query Trial($trialId: ID!) {
    trial(id: $trialId) {
      id
      conditions
      contacts {
        name
        email
        phone
      }
      criteria
      description
      gender
      identifiers
      interventions {
        description
        name
        synonyms
        type
      }
      keywords
      max_age
      min_age
      number
      origin
      phases
      sites {
        name
        city
        state
        country
        postal_code
        location {
          lat
          lng
        }
        contacts {
          name
          email
          phone
        }
      }
      sponsors
      status
      studytype
      summary
      title
      uri
    }
  }
`
