import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Loader, List, Segment, Header } from 'semantic-ui-react'
import { ReferralEvents_events } from './__generated__/ReferralEvents'
import { REFERRAL_EVENTS_QUERY } from './queries'
import moment from 'moment'

interface ReferralEventsListProps {
  referralId: string
}

const ReferralEventsList: React.FC<ReferralEventsListProps> = props => {
  const { loading, data } = useQuery(REFERRAL_EVENTS_QUERY, {
    variables: {
      input: {
        referral_id: props.referralId
      }
    }
  })

  let content
  if (loading) content = <Loader content="Loading Events" />
  if (!data || !data.events.length) content = <p>No Events</p>
  if (data && data.events.length) content = data.events.map((event: ReferralEvents_events) => (
    <List.Item key={event!.id}>
      <List.Content>
        <List.Header>{event!.description}</List.Header>
        <List.Description>
          {event!.status} - {moment(event!.updated_at).format('LLL')}
        </List.Description>
      </List.Content>
    </List.Item>
  ))

  return (
    <Segment vertical>
      <Header as="h3" dividing>Timeline</Header>
      <List divided>
        {content}
      </List>
    </Segment >
  )
}

export default ReferralEventsList
