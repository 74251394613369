import React from 'react'
import { Container, Header, Segment } from 'semantic-ui-react'
import { RouteComponentProps } from '@reach/router'
import { useAuth0 } from '../../auth/auth0'
import AnonymousDashboard from './Anonymous'

const Dashboard: React.FC<RouteComponentProps> = () => {
  const { isAuthenticated, loading } = useAuth0()

  if (loading) {
    return (
      <Container>
        <Segment vertical>
          <Header content="Loading" />
        </Segment>
      </Container>
    )
  }

  if (isAuthenticated) {
    return (
      <Container>
        <Segment vertical>
          <Header content="Dashboard" subheader="Nothing here yet. Use the menu to navigate around." />
        </Segment>
      </Container>
    )
  } else {
    return <AnonymousDashboard />
  }
}

export default Dashboard
