import React from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'

import { useAuth0 } from './auth0'

type Only = 'user' | 'anonymous' | 'admin'
type WithAuthProps = { only: Only; redirect?: string }
type Props = RouteComponentProps & WithAuthProps

const WithAuthWrapper: React.FC<Props> = props => {
  const { isAuthenticated } = useAuth0()

  let redirect
  if (props.only === 'user') {
    redirect = isAuthenticated ? undefined : props.redirect
  } else if (props.only === 'anonymous') {
    redirect = isAuthenticated ? props.redirect : undefined
  }

  if (redirect) {
    return <Redirect to={redirect} noThrow />
  } else {
    return <>{props.children}</>
  }
}

const withAuth = (Wrapped: React.ComponentType<RouteComponentProps>) => (
  props: RouteComponentProps & WithAuthProps
) => {
  const { only, redirect: redirectProp, ...passThrough } = props

  // use redirect passed on the route, otherwise login route
  const redirect = redirectProp || 'login'

  return (
    <WithAuthWrapper only={only} redirect={redirect}>
      <Wrapped {...passThrough} />
    </WithAuthWrapper>
  )
}

export default withAuth
